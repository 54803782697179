// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {

        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {

    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {

    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #1890ff;
    box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
    opacity: 0.2;
    -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    content: '';
    pointer-events: none;
}

@-webkit-keyframes waveEffect {
    100% {
        box-shadow: 0 0 0 #1890ff;
        box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
}

@keyframes waveEffect {
    100% {
        box-shadow: 0 0 0 #1890ff;
        box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
}

@-webkit-keyframes fadeEffect {
    100% {
        opacity: 0;
    }
}

@keyframes fadeEffect {
    100% {
        opacity: 0;
    }
}

.ant-slide-up-enter,
.ant-slide-up-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-slide-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-slide-up-leave.ant-slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-slide-up-enter,
.ant-slide-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-down-enter,
.ant-slide-down-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-slide-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-slide-down-leave.ant-slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-slide-down-enter,
.ant-slide-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-left-enter,
.ant-slide-left-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-slide-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
    -webkit-animation-name: antSlideLeftIn;
    animation-name: antSlideLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-slide-left-leave.ant-slide-left-leave-active {
    -webkit-animation-name: antSlideLeftOut;
    animation-name: antSlideLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-slide-left-enter,
.ant-slide-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-right-enter,
.ant-slide-right-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-slide-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
    -webkit-animation-name: antSlideRightIn;
    animation-name: antSlideRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-slide-right-leave.ant-slide-right-leave-active {
    -webkit-animation-name: antSlideRightOut;
    animation-name: antSlideRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-slide-right-enter,
.ant-slide-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes antSlideUpIn {
    0% {
        transform: scaleY(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }

    100% {
        transform: scaleY(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }
}

@keyframes antSlideUpIn {
    0% {
        transform: scaleY(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }

    100% {
        transform: scaleY(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }
}

@-webkit-keyframes antSlideUpOut {
    0% {
        transform: scaleY(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }

    100% {
        transform: scaleY(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }
}

@keyframes antSlideUpOut {
    0% {
        transform: scaleY(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }

    100% {
        transform: scaleY(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }
}

@-webkit-keyframes antSlideDownIn {
    0% {
        transform: scaleY(0.8);
        transform-origin: 100% 100%;
        opacity: 0;
    }

    100% {
        transform: scaleY(1);
        transform-origin: 100% 100%;
        opacity: 1;
    }
}

@keyframes antSlideDownIn {
    0% {
        transform: scaleY(0.8);
        transform-origin: 100% 100%;
        opacity: 0;
    }

    100% {
        transform: scaleY(1);
        transform-origin: 100% 100%;
        opacity: 1;
    }
}

@-webkit-keyframes antSlideDownOut {
    0% {
        transform: scaleY(1);
        transform-origin: 100% 100%;
        opacity: 1;
    }

    100% {
        transform: scaleY(0.8);
        transform-origin: 100% 100%;
        opacity: 0;
    }
}

@keyframes antSlideDownOut {
    0% {
        transform: scaleY(1);
        transform-origin: 100% 100%;
        opacity: 1;
    }

    100% {
        transform: scaleY(0.8);
        transform-origin: 100% 100%;
        opacity: 0;
    }
}

@-webkit-keyframes antSlideLeftIn {
    0% {
        transform: scaleX(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }

    100% {
        transform: scaleX(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }
}

@keyframes antSlideLeftIn {
    0% {
        transform: scaleX(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }

    100% {
        transform: scaleX(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }
}

@-webkit-keyframes antSlideLeftOut {
    0% {
        transform: scaleX(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }

    100% {
        transform: scaleX(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }
}

@keyframes antSlideLeftOut {
    0% {
        transform: scaleX(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }

    100% {
        transform: scaleX(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }
}

@-webkit-keyframes antSlideRightIn {
    0% {
        transform: scaleX(0.8);
        transform-origin: 100% 0%;
        opacity: 0;
    }

    100% {
        transform: scaleX(1);
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

@keyframes antSlideRightIn {
    0% {
        transform: scaleX(0.8);
        transform-origin: 100% 0%;
        opacity: 0;
    }

    100% {
        transform: scaleX(1);
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

@-webkit-keyframes antSlideRightOut {
    0% {
        transform: scaleX(1);
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        transform: scaleX(0.8);
        transform-origin: 100% 0%;
        opacity: 0;
    }
}

@keyframes antSlideRightOut {
    0% {
        transform: scaleX(1);
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        transform: scaleX(0.8);
        transform-origin: 100% 0%;
        opacity: 0;
    }
}

.ant-zoom-enter,
.ant-zoom-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
    -webkit-animation-name: antZoomIn;
    animation-name: antZoomIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-leave.ant-zoom-leave-active {
    -webkit-animation-name: antZoomOut;
    animation-name: antZoomOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-enter,
.ant-zoom-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
    transform: none;
}

.ant-zoom-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-enter,
.ant-zoom-big-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-big-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
    -webkit-animation-name: antZoomBigIn;
    animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-big-leave.ant-zoom-big-leave-active {
    -webkit-animation-name: antZoomBigOut;
    animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-big-enter,
.ant-zoom-big-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
    transform: none;
}

.ant-zoom-big-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-big-fast-leave {
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
    -webkit-animation-name: antZoomBigIn;
    animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
    -webkit-animation-name: antZoomBigOut;
    animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
    transform: none;
}

.ant-zoom-big-fast-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-up-enter,
.ant-zoom-up-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
    -webkit-animation-name: antZoomUpIn;
    animation-name: antZoomUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-up-leave.ant-zoom-up-leave-active {
    -webkit-animation-name: antZoomUpOut;
    animation-name: antZoomUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-up-enter,
.ant-zoom-up-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
    transform: none;
}

.ant-zoom-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-down-enter,
.ant-zoom-down-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
    -webkit-animation-name: antZoomDownIn;
    animation-name: antZoomDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-down-leave.ant-zoom-down-leave-active {
    -webkit-animation-name: antZoomDownOut;
    animation-name: antZoomDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-down-enter,
.ant-zoom-down-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
    transform: none;
}

.ant-zoom-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-left-enter,
.ant-zoom-left-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
    -webkit-animation-name: antZoomLeftIn;
    animation-name: antZoomLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-left-leave.ant-zoom-left-leave-active {
    -webkit-animation-name: antZoomLeftOut;
    animation-name: antZoomLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-left-enter,
.ant-zoom-left-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
    transform: none;
}

.ant-zoom-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-right-enter,
.ant-zoom-right-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
    -webkit-animation-name: antZoomRightIn;
    animation-name: antZoomRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-right-leave.ant-zoom-right-leave-active {
    -webkit-animation-name: antZoomRightOut;
    animation-name: antZoomRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-right-enter,
.ant-zoom-right-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
    transform: none;
}

.ant-zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomIn {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes antZoomIn {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes antZoomOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.2);
        opacity: 0;
    }
}

@keyframes antZoomOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.2);
        opacity: 0;
    }
}

@-webkit-keyframes antZoomBigIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes antZoomBigIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes antZoomBigOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
        opacity: 0;
    }
}

@keyframes antZoomBigOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
        opacity: 0;
    }
}

@-webkit-keyframes antZoomUpIn {
    0% {
        transform: scale(0.8);
        transform-origin: 50% 0%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 50% 0%;
    }
}

@keyframes antZoomUpIn {
    0% {
        transform: scale(0.8);
        transform-origin: 50% 0%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 50% 0%;
    }
}

@-webkit-keyframes antZoomUpOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 0%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 50% 0%;
        opacity: 0;
    }
}

@keyframes antZoomUpOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 0%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 50% 0%;
        opacity: 0;
    }
}

@-webkit-keyframes antZoomLeftIn {
    0% {
        transform: scale(0.8);
        transform-origin: 0% 50%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 0% 50%;
    }
}

@keyframes antZoomLeftIn {
    0% {
        transform: scale(0.8);
        transform-origin: 0% 50%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 0% 50%;
    }
}

@-webkit-keyframes antZoomLeftOut {
    0% {
        transform: scale(1);
        transform-origin: 0% 50%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 0% 50%;
        opacity: 0;
    }
}

@keyframes antZoomLeftOut {
    0% {
        transform: scale(1);
        transform-origin: 0% 50%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 0% 50%;
        opacity: 0;
    }
}

@-webkit-keyframes antZoomRightIn {
    0% {
        transform: scale(0.8);
        transform-origin: 100% 50%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 100% 50%;
    }
}

@keyframes antZoomRightIn {
    0% {
        transform: scale(0.8);
        transform-origin: 100% 50%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 100% 50%;
    }
}

@-webkit-keyframes antZoomRightOut {
    0% {
        transform: scale(1);
        transform-origin: 100% 50%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 100% 50%;
        opacity: 0;
    }
}

@keyframes antZoomRightOut {
    0% {
        transform: scale(1);
        transform-origin: 100% 50%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 100% 50%;
        opacity: 0;
    }
}

@-webkit-keyframes antZoomDownIn {
    0% {
        transform: scale(0.8);
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 50% 100%;
    }
}

@keyframes antZoomDownIn {
    0% {
        transform: scale(0.8);
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 50% 100%;
    }
}

@-webkit-keyframes antZoomDownOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 100%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 50% 100%;
        opacity: 0;
    }
}

@keyframes antZoomDownOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 100%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 50% 100%;
        opacity: 0;
    }
}

.ant-motion-collapse-legacy {
    // overflow: hidden;
}

.ant-motion-collapse-legacy-active {
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-motion-collapse {
    // overflow: hidden;
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-affix {
    position: fixed;
    z-index: 99999;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-alert {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    word-wrap: break-word;
    border-radius: 2px;
}

.ant-alert-content {
    // flex: 1;
    min-width: 0;
}

.ant-alert-icon {
    margin-right: 8px;
}

.ant-alert-description {
    display: none;
    font-size: 14px;
    line-height: 22px;
}

.ant-alert-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
}

.ant-alert-success .ant-alert-icon {
    color: #52c41a;
}

.ant-alert-info {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
}

.ant-alert-info .ant-alert-icon {
    color: #1890ff;
}

.ant-alert-warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
}

.ant-alert-warning .ant-alert-icon {
    color: #faad14;
}

.ant-alert-error {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
}

.ant-alert-error .ant-alert-icon {
    color: #ff4d4f;
}

.ant-alert-error .ant-alert-description>pre {
    margin: 0;
    padding: 0;
}

.ant-alert-action {
    margin-left: 8px;
}

.ant-alert-close-icon {
    margin-left: 8px;
    padding: 0;
    overflow: hidden;
    font-size: 12px;
    line-height: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.ant-alert-close-icon .anticon-close {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
}

.ant-alert-close-icon .anticon-close:hover {
    color: rgba(0, 0, 0, 0.75);
}

.ant-alert-close-text {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
}

.ant-alert-close-text:hover {
    color: rgba(0, 0, 0, 0.75);
}

.ant-alert-with-description {
    align-items: flex-start;
    padding: 15px 15px 15px 24px;
}

.ant-alert-with-description.ant-alert-no-icon {
    padding: 15px 15px;
}

.ant-alert-with-description .ant-alert-icon {
    margin-right: 15px;
    font-size: 24px;
}

.ant-alert-with-description .ant-alert-message {
    display: block;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
}

.ant-alert-message {
    color: rgba(0, 0, 0, 0.85);
}

.ant-alert-with-description .ant-alert-description {
    display: block;
}

.ant-alert.ant-alert-motion-leave {
    overflow: hidden;
    opacity: 1;
    transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-alert.ant-alert-motion-leave-active {
    max-height: 0;
    margin-bottom: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
}

.ant-alert-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
}

.ant-alert.ant-alert-rtl {
    direction: rtl;
}

.ant-alert-rtl .ant-alert-icon {
    margin-right: auto;
    margin-left: 8px;
}

.ant-alert-rtl .ant-alert-action {
    margin-right: 8px;
    margin-left: auto;
}

.ant-alert-rtl .ant-alert-close-icon {
    margin-right: 8px;
    margin-left: auto;
}

.ant-alert-rtl.ant-alert-with-description {
    padding-right: 24px;
    padding-left: 15px;
}

.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
    margin-right: auto;
    margin-left: 15px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-anchor {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    padding-left: 2px;
}

.ant-anchor-wrapper {
    margin-left: -4px;
    padding-left: 4px;
    overflow: auto;
    background-color: transparent;
}

.ant-anchor-ink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.ant-anchor-ink::before {
    position: relative;
    display: block;
    width: 2px;
    height: 100%;
    margin: 0 auto;
    background-color: #f0f0f0;
    content: ' ';
}

.ant-anchor-ink-ball {
    position: absolute;
    left: 50%;
    display: none;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid #1890ff;
    border-radius: 8px;
    transform: translateX(-50%);
    transition: top 0.3s ease-in-out;
}

.ant-anchor-ink-ball.visible {
    display: inline-block;
}

.ant-anchor-fixed .ant-anchor-ink .ant-anchor-ink-ball {
    display: none;
}

.ant-anchor-link {
    padding: 7px 0 7px 16px;
    line-height: 1.143;
}

.ant-anchor-link-title {
    position: relative;
    display: block;
    margin-bottom: 6px;
    //overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
}

.ant-anchor-link-title:only-child {
    margin-bottom: 0;
}

.ant-anchor-link-active>.ant-anchor-link-title {
    color: #1890ff;
}

.ant-anchor-link .ant-anchor-link {
    padding-top: 5px;
    padding-bottom: 5px;
}

.ant-anchor-rtl {
    direction: rtl;
}

.ant-anchor-rtl.ant-anchor-wrapper {
    margin-right: -4px;
    margin-left: 0;
    padding-right: 4px;
    padding-left: 0;
}

.ant-anchor-rtl .ant-anchor-ink {
    right: 0;
    left: auto;
}

.ant-anchor-rtl .ant-anchor-ink-ball {
    right: 50%;
    left: 0;
    transform: translateX(50%);
}

.ant-anchor-rtl .ant-anchor-link {
    padding: 7px 16px 7px 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-auto-complete {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
}

.ant-select-auto-complete .ant-select-clear {
    right: 13px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-single .ant-select-selector {
    display: flex;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
    width: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 30px;
    transition: all 0.3s;
}

@supports (-moz-appearance: meterbar) {

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 30px;
    }
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    transition: none;
    pointer-events: none;
}

.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 25px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
    color: #bfbfbf;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px;
    padding: 0 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 30px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
    line-height: 30px;
}

.ant-select-single.ant-select-customize-input .ant-select-selector::after {
    display: none;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
    position: static;
    width: 100%;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
    position: absolute;
    right: 0;
    left: 0;
    padding: 0 11px;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
    display: none;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 38px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
    right: 7px;
    left: 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
    right: 28px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 21px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
}

/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
    position: relative;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 100%;
}

.ant-select-selection-overflow-item {
    flex: none;
    align-self: center;
    max-width: 100%;
}

.ant-select-multiple .ant-select-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1px 4px;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
    cursor: text;
}

.ant-select-disabled.ant-select-multiple .ant-select-selector {
    background: #f5f5f5;
    cursor: not-allowed;
}

.ant-select-multiple .ant-select-selector::after {
    display: inline-block;
    width: 0;
    margin: 2px 0;
    line-height: 24px;
    content: '\a0';
}

.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 24px;
}

.ant-select-multiple .ant-select-selection-item {
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 22px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-margin-end: 4px;
    margin-inline-end: 4px;
    -webkit-padding-start: 8px;
    padding-inline-start: 8px;
    -webkit-padding-end: 4px;
    padding-inline-end: 4px;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: #bfbfbf;
    border-color: #d9d9d9;
    cursor: not-allowed;
}

.ant-select-multiple .ant-select-selection-item-content {
    display: inline-block;
    margin-right: 4px;
    //overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
}

.ant-select-multiple .ant-select-selection-item-remove {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    font-size: 10px;
    line-height: inherit;
    cursor: pointer;
}

.ant-select-multiple .ant-select-selection-item-remove>* {
    line-height: 1;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
    display: inline-block;
}

.ant-select-multiple .ant-select-selection-item-remove::before {
    display: none;
}

.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
    display: block;
}

.ant-select-multiple .ant-select-selection-item-remove>.anticon {
    vertical-align: -0.2em;
}

.ant-select-multiple .ant-select-selection-item-remove:hover {
    color: rgba(0, 0, 0, 0.75);
}

.ant-select-multiple .ant-select-selection-overflow-item+.ant-select-selection-overflow-item .ant-select-selection-search {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
}

.ant-select-multiple .ant-select-selection-search {
    position: relative;
    max-width: 100%;
    margin-top: 2px;
    margin-bottom: 2px;
    -webkit-margin-start: 7px;
    margin-inline-start: 7px;
}

.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
    height: 24px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 24px;
    transition: all 0.3s;
}

.ant-select-multiple .ant-select-selection-search-input {
    width: 100%;
    min-width: 4.1px;
}

.ant-select-multiple .ant-select-selection-search-mirror {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    white-space: pre;
    visibility: hidden;
}

.ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 11px;
    left: 11px;
    transform: translateY(-50%);
    transition: all 0.3s;
}

.ant-select-multiple.ant-select-lg .ant-select-selector::after {
    line-height: 32px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 32px;
    line-height: 30px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
    height: 32px;
    line-height: 32px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
    height: 32px;
    line-height: 30px;
}

.ant-select-multiple.ant-select-sm .ant-select-selector::after {
    line-height: 16px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 16px;
    line-height: 14px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
    height: 16px;
    line-height: 16px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
    height: 16px;
    line-height: 14px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    left: 7px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
    -webkit-margin-start: 3px;
    margin-inline-start: 3px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 32px;
    line-height: 32px;
}

.ant-select-disabled .ant-select-selection-item-remove {
    display: none;
}

/* Reset search input style */
.ant-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: auto;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important;
    outline: 0;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor: not-allowed;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f5f5f5;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: not-allowed;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
    display: none;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px !important;
}

.ant-select-selection-item {
    // flex: 1;
    //overflow: hidden;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media all and (-ms-high-contrast: none) {

    .ant-select-selection-item *::-ms-backdrop,
    .ant-select-selection-item {
        flex: auto;
    }
}

.ant-select-selection-placeholder {
    // flex: 1;
    //overflow: hidden;
    color: #bfbfbf;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
}

@media all and (-ms-high-contrast: none) {

    .ant-select-selection-placeholder *::-ms-backdrop,
    .ant-select-selection-placeholder {
        flex: auto;
    }
}

.ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 11px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
}

.ant-select-arrow>* {
    line-height: 1;
}

.ant-select-arrow svg {
    display: inline-block;
}

.ant-select-arrow::before {
    display: none;
}

.ant-select-arrow .ant-select-arrow-icon {
    display: block;
}

.ant-select-arrow .anticon {
    vertical-align: top;
    transition: transform 0.3s;
}

.ant-select-arrow .anticon>svg {
    vertical-align: top;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: auto;
}

.ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
}

.ant-select-clear {
    position: absolute;
    top: 50%;
    right: 11px;
    z-index: 9999;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    transition: color 0.3s ease, opacity 0.15s ease;
    text-rendering: auto;
}

.ant-select-clear::before {
    display: block;
}

.ant-select-clear:hover {
    color: rgba(0, 0, 0, 0.45);
}

.ant-select:hover .ant-select-clear {
    opacity: 1;
}

.ant-select-dropdown {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 9999999;
    box-sizing: border-box;
    padding: 4px 0;
    //overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
}

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
}

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
}

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
}

.ant-select-dropdown-hidden {
    display: none;
}

.ant-select-dropdown-empty {
    color: rgba(0, 0, 0, 0.25);
}

.ant-select-item-empty {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.25);
}

.ant-select-item {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.ant-select-item-group {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    cursor: default;
}

.ant-select-item-option {
    display: flex;
}

.ant-select-item-option-content {
    flex: auto;
    //overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-select-item-option-state {
    flex: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #e6f7ff;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #1890ff;
}

.ant-select-item-option-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.ant-select-item-option-disabled.ant-select-item-option-selected {
    background-color: #f5f5f5;
}

.ant-select-item-option-grouped {
    padding-left: 24px;
}

.ant-select-lg {
    font-size: 16px;
}

.ant-select-borderless .ant-select-selector {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.ant-select-rtl {
    direction: rtl;
}

.ant-select-rtl .ant-select-arrow {
    right: initial;
    left: 11px;
}

.ant-select-rtl .ant-select-clear {
    right: initial;
    left: 11px;
}

.ant-select-dropdown-rtl {
    direction: rtl;
}

.ant-select-dropdown-rtl .ant-select-item-option-grouped {
    padding-right: 24px;
    padding-left: 12px;
}

.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 4px;
    padding-left: 24px;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
    text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
    margin-right: 0;
    margin-left: 4px;
    text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
    right: 0;
    left: auto;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
    right: 11px;
    left: auto;
}

.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    right: 7px;
}

.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    right: 0;
    left: 9px;
    text-align: right;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 11px;
    left: 25px;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0;
    padding-left: 18px;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
    right: 6px;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0;
    padding-left: 21px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-empty {
    margin: 0 8px;
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
}

.ant-empty-image {
    height: 100px;
    margin-bottom: 8px;
}

.ant-empty-image img {
    height: 100%;
}

.ant-empty-image svg {
    height: 100%;
    margin: auto;
}

.ant-empty-footer {
    margin-top: 16px;
}

.ant-empty-normal {
    margin: 32px 0;
    color: rgba(0, 0, 0, 0.25);
}

.ant-empty-normal .ant-empty-image {
    height: 40px;
}

.ant-empty-small {
    margin: 8px 0;
    color: rgba(0, 0, 0, 0.25);
}

.ant-empty-small .ant-empty-image {
    height: 35px;
}

.ant-empty-img-default-ellipse {
    fill: #f5f5f5;
    fill-opacity: 0.8;
}

.ant-empty-img-default-path-1 {
    fill: #aeb8c2;
}

.ant-empty-img-default-path-2 {
    fill: url('#linearGradient-1');
}

.ant-empty-img-default-path-3 {
    fill: #f5f5f7;
}

.ant-empty-img-default-path-4 {
    fill: #dce0e6;
}

.ant-empty-img-default-path-5 {
    fill: #dce0e6;
}

.ant-empty-img-default-g {
    fill: #fff;
}

.ant-empty-img-simple-ellipse {
    fill: #f5f5f5;
}

.ant-empty-img-simple-g {
    stroke: #d9d9d9;
}

.ant-empty-img-simple-path {
    fill: #fafafa;
}

.ant-empty-rtl {
    direction: rtl;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    //overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
}

.ant-avatar-image {
    background: transparent;
}

.ant-avatar .ant-image-img {
    display: block;
}

.ant-avatar-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
}

.ant-avatar.ant-avatar-icon {
    font-size: 18px;
}

.ant-avatar.ant-avatar-icon>.anticon {
    margin: 0;
}

.ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
}

.ant-avatar-lg-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
}

.ant-avatar-lg.ant-avatar-icon {
    font-size: 24px;
}

.ant-avatar-lg.ant-avatar-icon>.anticon {
    margin: 0;
}

.ant-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
}

.ant-avatar-sm-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
}

.ant-avatar-sm.ant-avatar-icon {
    font-size: 14px;
}

.ant-avatar-sm.ant-avatar-icon>.anticon {
    margin: 0;
}

.ant-avatar-square {
    border-radius: 2px;
}

.ant-avatar>img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.ant-avatar-group {
    display: inline-flex;
}

.ant-avatar-group .ant-avatar {
    border: 1px solid #fff;
}

.ant-avatar-group .ant-avatar:not(:first-child) {
    margin-left: -8px;
}

.ant-avatar-group-popover .ant-avatar+.ant-avatar {
    margin-left: 3px;
}

.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
    margin-right: -8px;
    margin-left: 0;
}

.ant-avatar-group-popover.ant-popover-rtl .ant-avatar+.ant-avatar {
    margin-right: 3px;
    margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-carousel {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
}

.ant-carousel .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}

.ant-carousel .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    //overflow: hidden;
}

.ant-carousel .slick-list:focus {
    outline: none;
}

.ant-carousel .slick-list.dragging {
    cursor: pointer;
}

.ant-carousel .slick-list .slick-slide {
    pointer-events: none;
}

.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
    visibility: hidden;
}

.ant-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto;
}

.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
    visibility: visible;
}

.ant-carousel .slick-list .slick-slide>div>div {
    vertical-align: bottom;
}

.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    touch-action: pan-y;
}

.ant-carousel .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
    display: table;
    content: '';
}

.ant-carousel .slick-track::after {
    clear: both;
}

.slick-loading .ant-carousel .slick-track {
    visibility: hidden;
}

.ant-carousel .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.ant-carousel .slick-slide img {
    display: block;
}

.ant-carousel .slick-slide.slick-loading img {
    display: none;
}

.ant-carousel .slick-slide.dragging img {
    pointer-events: none;
}

.ant-carousel .slick-initialized .slick-slide {
    display: block;
}

.ant-carousel .slick-loading .slick-slide {
    visibility: hidden;
}

.ant-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto;
}

.ant-carousel .slick-arrow.slick-hidden {
    display: none;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    color: transparent;
    background: transparent;
    outline: none;
}

.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
    opacity: 1;
}

.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
    opacity: 0.25;
}

.ant-carousel .slick-prev {
    left: -25px;
}

.ant-carousel .slick-prev::before {
    content: '←';
}

.ant-carousel .slick-next {
    right: -25px;
}

.ant-carousel .slick-next::before {
    content: '→';
}

.ant-carousel .slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    display: flex !important;
    justify-content: center;
    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;
    list-style: none;
}

.ant-carousel .slick-dots-bottom {
    bottom: 12px;
}

.ant-carousel .slick-dots-top {
    top: 12px;
    bottom: auto;
}

.ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 16px;
    height: 3px;
    margin: 0 2px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
}

.ant-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #fff;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s;
}

.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
    opacity: 0.75;
}

.ant-carousel .slick-dots li.slick-active {
    width: 24px;
}

.ant-carousel .slick-dots li.slick-active button {
    background: #fff;
    opacity: 1;
}

.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
    opacity: 1;
}

.ant-carousel-vertical .slick-dots {
    top: 50%;
    bottom: auto;
    flex-direction: column;
    width: 3px;
    height: auto;
    margin: 0;
    transform: translateY(-50%);
}

.ant-carousel-vertical .slick-dots-left {
    right: auto;
    left: 12px;
}

.ant-carousel-vertical .slick-dots-right {
    right: 12px;
    left: auto;
}

.ant-carousel-vertical .slick-dots li {
    width: 3px;
    height: 16px;
    margin: 4px 2px;
    vertical-align: baseline;
}

.ant-carousel-vertical .slick-dots li button {
    width: 3px;
    height: 16px;
}

.ant-carousel-vertical .slick-dots li.slick-active {
    width: 3px;
    height: 24px;
}

.ant-carousel-vertical .slick-dots li.slick-active button {
    width: 3px;
    height: 24px;
}

.ant-carousel-rtl {
    direction: rtl;
}

.ant-carousel-rtl .ant-carousel .slick-track {
    right: 0;
    left: auto;
}

.ant-carousel-rtl .ant-carousel .slick-prev {
    right: -25px;
    left: auto;
}

.ant-carousel-rtl .ant-carousel .slick-prev::before {
    content: '→';
}

.ant-carousel-rtl .ant-carousel .slick-next {
    right: auto;
    left: -25px;
}

.ant-carousel-rtl .ant-carousel .slick-next::before {
    content: '←';
}

.ant-carousel-rtl.ant-carousel .slick-dots {
    flex-direction: row-reverse;
}

.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
    flex-direction: column;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        transform: scale(1.6);
        opacity: 0;
    }
}

@keyframes antCheckboxEffect {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        transform: scale(1.6);
        opacity: 0;
    }
}

.ant-cascader-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    top: 0.2em;
    line-height: 1;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
}

.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox-input:focus+.ant-cascader-checkbox-inner {
    border-color: #1890ff;
}

.ant-cascader-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    content: '';
}

.ant-cascader-checkbox:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox::after {
    visibility: visible;
}

.ant-cascader-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
}

.ant-cascader-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 21.5%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
}

.ant-cascader-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
}

.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
}

.ant-cascader-checkbox-disabled {
    cursor: not-allowed;
}

.ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
    animation-name: none;
}

.ant-cascader-checkbox-disabled .ant-cascader-checkbox-input {
    cursor: not-allowed;
}

.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
}

.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
    border-color: #f5f5f5;
    border-collapse: separate;
    -webkit-animation-name: none;
    animation-name: none;
}

.ant-cascader-checkbox-disabled+span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.ant-cascader-checkbox-disabled:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-disabled::after {
    visibility: hidden;
}

.ant-cascader-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    display: inline-flex;
    align-items: baseline;
    line-height: unset;
    cursor: pointer;
}

.ant-cascader-checkbox-wrapper::after {
    display: inline-block;
    width: 0;
    //overflow: hidden;
    content: '\a0';
}

.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-disabled {
    cursor: not-allowed;
}

.ant-cascader-checkbox-wrapper+.ant-cascader-checkbox-wrapper {
    margin-left: 8px;
}

.ant-cascader-checkbox+span {
    padding-right: 8px;
    padding-left: 8px;
}

.ant-cascader-checkbox-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    display: inline-block;
}

.ant-cascader-checkbox-group-item {
    margin-right: 8px;
}

.ant-cascader-checkbox-group-item:last-child {
    margin-right: 0;
}

.ant-cascader-checkbox-group-item+.ant-cascader-checkbox-group-item {
    margin-left: 0;
}

.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;
}

.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
}


.ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
}

//.ant-cascader {
//    width: 184px;
//}
.ant-cascader-checkbox {
    top: 0;
    margin-right: 8px;
}

.ant-cascader-menus {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.ant-cascader-menus.ant-cascader-menu-empty .ant-cascader-menu {
    width: 100%;
    height: auto;
}

.ant-cascader-menu {
    min-width: 111px;
    height: 180px;
    margin: 0;
    margin: -4px 0;
    padding: 4px 0;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    border-right: 1px solid #f0f0f0;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ant-cascader-menu-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 5px 12px;
    //overflow: hidden;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;
}

.ant-cascader-menu-item:hover {
    background: #f5f5f5;
}

.ant-cascader-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.ant-cascader-menu-item-disabled:hover {
    background: transparent;
}

.ant-cascader-menu-empty .ant-cascader-menu-item {
    color: rgba(0, 0, 0, 0.25);
    cursor: default;
    pointer-events: none;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: 600;
    background-color: #e6f7ff;
}

.ant-cascader-menu-item-content {
    flex: auto;
}

.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
}

.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
    color: rgba(0, 0, 0, 0.25);
}

.ant-cascader-menu-item-keyword {
    color: #ff4d4f;
}

.ant-cascader-rtl .ant-cascader-menu-item-expand-icon,
.ant-cascader-rtl .ant-cascader-menu-item-loading-icon {
    margin-right: 4px;
    margin-left: 0;
}

.ant-cascader-rtl .ant-cascader-checkbox {
    top: 0;
    margin-right: 0;
    margin-left: 8px;
}

.ag-theme-alpine .ag-header-cell-label {
    font-style: italic;
}


.ag-theme-alpine {


    .ag-cell {
        border-right: 1px solid ag-param(secondary-border-color);
    }
}

.tableuser {
    border-top: 1px solid #ccc;
    border-spacing: 0;
    border-left: 1px solid #babfc7;
    width: 100%;
    // min-width: 550px;

    td {
        padding: 10px 5px;
        border-bottom: 1px solid #babfc7;
        border-right: 1px solid #babfc7;

        button {
            padding: 0 5px;
            min-width: auto;

            word-break: keep-all;
            white-space: nowrap;
        }
    }

    th {
        text-align: left;
        padding: 10px 5px;
        border-bottom: 1px solid #babfc7;
        font-size: 18px;
        border-right: 1px solid #babfc7;
        word-break: keep-all;
        white-space: nowrap;

        button {
            padding: 0 5px;
            min-width: auto;
            word-break: keep-all;
            white-space: nowrap;
            color: #333;
            font-weight: normal;
        }
    }
}

table {

    td,
    th {
        word-break: keep-all;
        white-space: nowrap;
    }
}



a:link,
a:visited {
    color: #737373;
}

.ag-root .my-row:is(.ag-row-selected) {
    background-color: #ede7f6;
    color: #5e36b1;
}

.BuyUserPackingtypeMaterialtable {
    border-top: 1px dashed #ccc;
    border-spacing: 0;
    border-left: 1px dashed #babfc7;
    width: 100%;
    min-width: 750px;
    font-size: 20px;

    td {
        border-bottom: 1px dashed #babfc7;
        border-right: 1px dashed #babfc7;
        padding: 0 !important;
        width: 16%;
    }

    th {
        text-align: left;
        border-bottom: 1px dashed #babfc7;
        border-right: 1px dashed #babfc7;
        word-break: keep-all;
        white-space: nowrap;
        padding: 10px;
        width: 16%;
        font-weight: normal;
    }

    input {
        width: 100%;
        height: 100%;
        display: block;
        font-size: 20px;
        background: #f8f8f8;
    }

    select {
        width: 100%;
        height: 100%;
        display: block;
        font-size: 20px;
        background: #f8f8f8;
        border: none;
        min-width: 180px;
    }
}

.cccddds .MuiOutlinedInput-root {
    padding: inherit;

}

.cccddds .MuiAutocomplete-hasPopupIcon.css-1s64huy-MuiAutocomplete-root .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon.css-1s64huy-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    padding-right: inherit;
}

.alltabs {
    button {
        width: 22%;
        margin: 10px 10px 0px 0;
        padding: 10px 0;
        font-size: 20px;
        display: inline-block;
        min-width: auto;
    }
}

.userred {
    font-size: 20px;

    input {
        color: #8c00ff !important;
        font-weight: bold;
    }
}

.text-pop-up-top {
    -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

@keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

.text-shadow-pop-top {
    -webkit-animation: text-shadow-pop-top 0.6s both;
    animation: text-shadow-pop-top 0.6s both;
}


@-webkit-keyframes text-shadow-pop-top {
    0% {
        text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        text-shadow: 0 -1px #555555, 0 -2px #555555, 0 -3px #555555, 0 -4px #555555, 0 -5px #555555, 0 -6px #555555, 0 -7px #555555, 0 -8px #555555;
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
}

@keyframes text-shadow-pop-top {
    0% {
        text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        text-shadow: 0 -1px #555555, 0 -2px #555555, 0 -3px #555555, 0 -4px #555555, 0 -5px #555555, 0 -6px #555555, 0 -7px #555555, 0 -8px #555555;
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
}

.css-1h2ex2j-MuiFormLabel-root-MuiInputLabel-root {
    // font-size: 20px !important;
    color: #555 !important;
    font-size: 1.3em !important;
}

.css-1in441m {
    font-size: 1em !important;
}

.semi-modal-mask {
    background: rgba(0, 0, 0, 0.1) !important;
}

//.tbl {
//
//    table {
//        border-collapse:collapse;
//        border-spacing:0;
//        width: 100%;
//    }
//    th {
//        border: 1px solid black;
//    }
//
//    td {
//        border: 1px solid black;
//    }
//}
//
.css-j4uk0q-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: black;
}

.css-j4uk0q-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 1.2rem;
}

.css-45w82j-MuiPaper-root-MuiDialog-paper {
    max-width: 80vw !important;
}

.semi-portal {
    z-index: 999999999 !important;
}

.css-1dnrbpp {
    max-width: 80vw !important;
}

.my-header-class {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}

.my-table {
    border-collapse: collapse;
    border: 1px solid black;
    width: 100%;
    margin: 0 auto;

    th,
    td {
        border: 1px solid black;
        text-align: left;
        padding-left: 8px;
        color: #000;
    }



    th {

        padding: 10px 0 10px 8px;
    }

    //tr:nth-child(even) {
    //    background-color: #f2f2f2;
    //}

    th:nth-child(1) {
        font-size: 18px;
    }
}




/* CylinderPriceTable.css */
.table-container {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
}

.table-container th,
.table-container td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.table-container th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table-container tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table-container tr:hover {
    background-color: #ddd;
}

.table-container td.editable {
    cursor: pointer;
}

/* 给单元格添加内边框样式 */
.ag-theme-balham .ag-row .ag-cell {
    border-right: 1px solid #f2f2f2;
    /* 右边框样式 */
}

/* 移除表格最后一列的右边框 */
.ag-theme-balham .ag-center-cols-viewport .ag-row .ag-cell:last-child {
    border-right: none;
}

.semi-checkbox-cardType {
    padding: 8px 16px;
    border: 1px solid #f2f2f2;
}

.css-kvip5o-MuiToolbar-root {
    padding: 0 16px !important;
}


::v-deep .ag-layout-normal {
    overflow-y: overlay;
}

::v-deep .ag-theme-alpine-dark:hover .ag-body-horizontal-scroll-viewport {
    margin-right: -8px;
}

/* 定义垂直滚动条的宽度和颜色 */
.ag-root .ag-body-viewport::-webkit-scrollbar {
    width: 10px !important;
    /* 调整宽度 */
}

.ag-root .ag-body-viewport::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    /* 滚动条的颜色 */
    border-radius: 4px !important;
    /* 圆角 */
}

.ag-root .ag-body-viewport::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important;
    /* 鼠标悬停时的颜色 */
}

/* 定义水平滚动条的高度和颜色 */
.ag-root .ag-body-viewport::-webkit-scrollbar-horizontal {
    height: 10px !important;
    /* 调整高度 */
}

.ag-root .ag-body-viewport::-webkit-scrollbar-button {
    display: none !important;
    /* 隐藏滚动条按钮 */
}
.my-custom-cell-class {
    font-size: 17px;
}
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 16px;
}

.ag-theme-balham .ag-cell, .ag-theme-balham .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 16px;
}

/* 遮罩层 */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999999999; /* 高的层级值 */
}

/* 弹窗 */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10000000000; /* 比遮罩层更高的层级值 */
    width: 300px; /* 或其他您需要的宽度 */
}

.popule {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    padding: 20px;
    border: 1px solid #ccc;
    color: white;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10000000000; /* 比遮罩层更高的层级值 */
    width: 400px; /* 或其他您需要的宽度 */
}

.popule-item{
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 10px;
}

//-webkit-scrollbar {
//    width: 0px;
//}
//
//.maincontent::-webkit-scrollbar {
//    width: 10px !important;
//}
#print {
    //.css-1bcrwy4-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    //    color: red;
    //    font-weight: bold;
    //    border-color: red;
    //    font-size: 16px;
    //}
    .Mui-selected {
        color: red;
        font-weight: bold;
        border-color: red;
        font-size: 16px;
    }
}