@keyframes changeBackgroundColor {
    0% { background-color: red; }
    25% { background-color: blue; }
    50% { background-color: green; }
    75% { background-color: yellow; }
    100% { background-color: red; }
}

.belingchange {

    animation: changeBackgroundColor 1s infinite, vibrate-3 0.5s linear infinite both;

}

.vibrate-3 {
    -webkit-animation: vibrate-3 0.5s linear infinite both;
    animation: vibrate-3 0.5s linear infinite both;
}


@-webkit-keyframes vibrate-3 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    10% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }
    20% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }
    30% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }
    40% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }
    50% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }
    60% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }
    70% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }
    80% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }
    90% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }
    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}
@keyframes vibrate-3 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    10% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }
    20% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }
    30% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }
    40% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }
    50% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }
    60% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }
    70% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }
    80% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }
    90% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }
    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}
