.softphoneroom {
    .softphone-container {
        width: 400px;
        margin: 20px auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        background-color: #f9f9f9;
    }

    .status-idle {
        border-color: #28a745; /* 绿色代表空闲 */
        background-color: #e9f8ee;
    }

    .status-busy {
        border-color: #dc3545; /* 红色代表忙碌 */
        background-color: #f8e9e9;
    }

    .status-break {
        border-color: #ffc107; /* 黄色代表小休 */
        background-color: #fff3cd;
    }

    .seat-status {
        margin-bottom: 20px;
    }

    .phone-controls {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    input[type="text"] {
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
    }

    button {
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    button:hover {
        background-color: #0056b3;
    }

    .call-history {
        max-height: 200px;
        overflow-y: scroll;
        margin-top: 20px;
    }

    .call-history ul {
        list-style-type: none;
        padding: 0;
    }

    .call-history li {
        padding: 8px;
        border-bottom: 1px solid #ddd;
    }

    .call-history li a {
        color: #007bff;
        text-decoration: none;
    }

    .call-history li a:hover {
        text-decoration: underline;
    }
}
